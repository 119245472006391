import styled from 'styled-components';
import { color, font, mediaBreakpointUp, hover, mediaBreakpointDown, vw, buttonStyle, offset } from '@/style/mixins';
import Picture from '@/components/common/Picture/Picture';
import RectLink from '@/components/ui/RectLink/RectLink';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';

export const Container = styled.div`
    width: calc(100vw - 20px * 2);
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    color: ${color('text-oncolor-primary')};
    ${offset('m', 'padding')}

    ${mediaBreakpointUp('xl')} {
        width: 592px;
    }

    ${hover(`
        img {
            transform: scale(1.03);
        }
    `)};
`;

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
`;

export const Title = styled.p`
    ${font('h2')};

    ${mediaBreakpointUp('md')} {
        max-width: ${vw(520, 'md')};
    }

    ${mediaBreakpointUp('xl')} {
        max-width: none;
    }
`;

export const Text = styled.div`
    max-width: ${vw(255, 'xs')};
    ${font('t1-body-sb')};
    color: ${color('text-oncolor-secondary', 0.8)};
    ${offset('m', 'margin-top')}

    ${mediaBreakpointUp('md')} {
        max-width: ${vw(520, 'md')};
    }

    ${mediaBreakpointUp('xl')} {
        max-width: 464px;
    }

    ul {
        ${font('text2-b')};
        color: ${color('white')};
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style-type: none;

        li {
            display: flex;
            align-items: center;
        }

        li::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            background: ${color('white')};
            border-radius: 1px;
            margin-right: 10px;
        }
    }
`;

export const Buttons = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${mediaBreakpointUp('md')} {
        margin-top: auto;
        flex-direction: row;
    }

    a:not(:first-child) {
        color: ${color('white')};
    }

    ${ModalBtn} {
        ${buttonStyle(true, false, undefined, 'Content-01')};
        ${mediaBreakpointDown('md')} {
            align-self: auto;
        }
    }
`;

export const Button = styled(RectLink)`
    align-self: stretch;
    ${mediaBreakpointUp('md')} {
        align-self: flex-start;
    }
`;

export const BackgroundPicture = styled(Picture)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    will-change: transform;

    img {
        transition: transform 0.3s ease-in-out;
    }
`;

export const Angle = styled.div`
    position: absolute;
    z-index: 1;
    display: block;
    width: 32px;
    height: 32px;
    user-select: none;

    ${mediaBreakpointDown('xl')} {
        display: none;
    }

    &.top-left {
        top: -1px;
        left: -1px;
        transform: rotate(90deg);
    }

    &.top-right {
        top: -1px;
        right: -1px;
        transform: rotate(180deg);
    }

    &.bottom-left {
        bottom: -1px;
        left: -1px;
    }

    &.bottom-right {
        bottom: -1px;
        right: -1px;
        transform: rotate(-90deg);
    }

    svg {
        path {
            fill: ${color('Bg-Light')};
        }
    }
`;
