import styled from 'styled-components';
import { mediaBreakpointUp, mediaBreakpointDown } from '@/style/mixins';
import { Container as Card1 } from '@/componentsNew/blocks/Cards/Card1/Card1.styled';
import { Container as Card4 } from '@/componentsNew/blocks/Cards/Card4/Card4.styled';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px 12px;
    align-items: stretch;

    ${mediaBreakpointDown('md')} {
        justify-content: space-between;
    }

    ${mediaBreakpointUp('md')} {
        gap: 16px;
    }

    ${Card1} {
        min-height: 122px;

        ${mediaBreakpointDown('md')} {
            padding: 0 4px;
        }

        ${mediaBreakpointUp('md')} {
            min-height: 220px;
            padding: 24px;
        }

        ${mediaBreakpointUp('xl')} {
            min-height: 288px;
        }
    }

    ${Card4} {
        height: 288px;
    }

    ${ModalBtn} {
        margin: 0;
    }

    & > * {
        width: calc((100% - 12px) / 2);

        ${mediaBreakpointUp('md')} {
            width: calc((100% - 16px * 2) / 3);
        }

        ${mediaBreakpointUp('xl')} {
            width: calc((100% - 16px * 3) / 4);
        }
    }
`;
